<template>
  <div>
    <v-container>
      <h1>{{ $t("app.roomControl") }}</h1>
      <v-row class="pa-4 pb-0">
        <v-col
          cols="1"
          v-if="selectedParentGroup.iconImage"
          class="pa-0"
          align-self="center"
        >
          <v-img
            contain
            aspect-ratio="1"
            :src="getMediaPoolSrc(selectedParentGroup.iconImage)"
            :lazy-src="getMediaPoolSrc(selectedParentGroup.iconImage)"
          ></v-img>
        </v-col>
        <v-col>
          <v-select
            :items="parentGroups"
            v-model="selectedParentGroup"
            return-object
            item-value="id"
            item-text="title"
            :label="$t('roomControl.selectControl')"
          >
            <template slot="item" slot-scope="data">
              <v-img
                contain
                aspect-ratio="1"
                v-if="data.item.iconImage"
                :src="getMediaPoolSrc(data.item.iconImage)"
                :lazy-src="getMediaPoolSrc(data.item.iconImage)"
                max-width="36"
                max-height="36"
                class="mr-2"
              ></v-img>
              <div>{{ getTranslation(data.item.i18n).title }}</div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row
        v-if="selectedParentGroup.child"
        v-for="childGroup in selectedParentGroup.child"
        :key="childGroup.id"
      >
        <v-col>
          <v-card outlined>
            <v-card-title
              v-if="
                childGroup.iconImage || getTranslation(childGroup.i18n).title
              "
            >
              <v-container>
                <v-row>
                  <v-col cols="2" class="pa-0" v-if="childGroup.iconImage">
                    <v-img
                      contain
                      aspect-ratio="1"
                      :src="getMediaPoolSrc(childGroup.iconImage)"
                      :lazy-src="getMediaPoolSrc(childGroup.iconImage)"
                      max-width="36"
                      max-height="36"
                    ></v-img>
                  </v-col>
                  <v-col class="pa-0">
                    <div class="text-no-wrap text-truncate">
                      {{ getTranslation(childGroup.i18n).title }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text v-if="getTranslation(childGroup.i18n).description">{{
              getTranslation(childGroup.i18n).description
            }}</v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col
                    class="pa-0"
                    v-for="command in childGroup.command"
                    :data-id="command.id"
                    :key="command.id"
                    :cols="command.actionType === 'button' ? '' : '12'"
                  >
                    <Command :command="command"></Command>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-if="selectedParentGroup.command"
        v-for="command in selectedParentGroup.command"
        :key="command.id"
      >
        <Command class="mt-2" :command="command"></Command>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ROOT } from "@/config";
import Command from "@/components/roomControl/Command";
export default {
  data: () => ({
    selectedParentGroup: {},
  }),
  computed: {
    ...mapState("session", ["settings", "authResult"]),
    ...mapState("roomControl", ["items", "state"]),
    parentGroups() {
      const groups = this.items.map((el) => ({
        ...el,
        title: this.getTranslation(el.i18n).title,
      }));
      this.selectedParentGroup = groups[0] || {};
      return groups;
    },
  },
  mounted() {
    this.$store.dispatch("roomControl/getRoomControl");
  },
  components: {
    Command,
  },
  methods: {
    getTranslation(i18n) {
      return (
        i18n.filter(
          (el) => el.language === this.$config.language_key.toLowerCase()
        )[0] || {}
      );
    },
    getMediaPoolSrc(mediaPoolItem) {
      return `${ROOT}media/${mediaPoolItem.propertyId}/${mediaPoolItem._id}.${mediaPoolItem.ext}`;
    },
  },
};
</script>
