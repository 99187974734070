<template>
  <v-container>
    <v-row justify="center">
      <v-btn
        x-large
        @click.prevent="$emit('change', { commandId, value: '' })"
        @click.native.prevent
        :fab="iconUrl !== ''"
        elevation="2"
        class="mb-2 overflow-hidden"
      >
        <v-img
          cover
          aspect-ratio="1"
          v-if="iconUrl"
          :src="iconUrl"
          :lazy-src="iconUrl"
          max-width="72"
          max-height="72"
        ></v-img>
        <div v-else>
          {{ title }}
        </div>
      </v-btn>
    </v-row>
    <v-row class="text-center" justify="center" v-if="iconUrl">
      {{ title }}
    </v-row>
    <v-row class="text-center" justify="center">
      {{ description }}
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RoomControlButton",
  props: {
    commandId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    iconUrl: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
  },
};
</script>
