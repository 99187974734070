<template>
  <v-container>
    <v-row justify="space-between">
      <v-col v-if="iconUrl" cols="2">
        <v-img
          cover
          aspect-ratio="1"
          :src="iconUrl"
          :lazy-src="iconUrl"
          max-width="48"
          max-height="48"
        ></v-img>
      </v-col>
      <v-col>
        <v-btn-toggle v-model="selectedBtnIdx" mandatory>
          <v-btn
            @click.prevent="$emit('change', { commandId, value: 'off' })"
            @click.native.prevent
            min-width="5rem"
          >
            {{ offValue }}
          </v-btn>
          <v-btn
            @click.prevent="$emit('change', { commandId, value: 'on' })"
            @click.native.prevent
            min-width="5rem"
          >
            {{ onValue }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row justify="center">
      {{ title }}
    </v-row>
    <v-row justify="center">
      {{ description }}
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RoomControlToggleButton",
  data: () => ({
    selectedBtnIdx: 0,
  }),
  watch: {
    state(newVal) {
      this.selectedBtnIdx = this.state === "on" ? 1 : 0;
    },
  },
  mounted() {
    this.selectedBtnIdx = this.state === "on" ? 1 : 0;
  },
  props: {
    commandId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    onValue: {
      type: String,
      default: "",
    },
    offValue: {
      type: String,
      default: "",
    },
    iconUrl: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
  },
};
</script>
